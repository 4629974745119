@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/_variables';

main {
  @extend .container, .my-4;
}

input {
  @extend .form-control;
}

$body-bg: #335;
$body-bg: #111120;

$body-color: #bbd;

button {
  @extend .btn, .btn-primary, .text-secondary;
}

$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': #579,
    'secondary': #cce,
  )
);

#timer-input {
  @extend .input-group, .my-4;
  input {
    @extend .form-control, .bg-secondary;
  }
  button {
    @extend .input-group-text, .btn-primary;
  }
}

#timer-container {
  @extend .d-flex, .flex-row, .flex-wrap, .my-4;
  gap: 4rem;
  // justify-content: space-between;
  button {
    width: 4.5rem;
  }
}

.button-group {
  @extend .d-flex, .flex-row, .flex-wrap;
  gap: 1rem;
}

@import '../node_modules/bootstrap/scss/bootstrap.scss';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';
